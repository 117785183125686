import React from "react";
import { Box, Image } from "../styles/Styles";
import united from "../img/united-logo.svg";
import usta from "../img/usta-logo.svg";
import procore from "../img/procore-logo.svg";
import patriots from "../img/patriots-icon.svg";
import national from "../img/nationalgrid-logo.svg";
import jetblue from "../img/jetblue-logo.svg";
import intact from "../img/intact-logo.svg";
import ford from "../img/ford-logo.svg";
import amadeus from "../img/amadeus-logo.svg";
import delta from "../img/delta-logo.svg";
import via from "../img/via-logo.svg";
import flynn from "../img/flynn-logo.svg";
import jcaii from "../img/jcaii-logo.svg";
import aws from "../img/aws-logo.svg";
import uas from "../img/uasidekick-logo.svg";

const logos = [delta, jetblue, ford, patriots, united, via, amadeus, aws, jcaii, flynn, national, intact, procore, usta, uas];


const CardCustomers = () => {
  return (
    <Box
      as="a"
      href="/customer-stories/"
      bg="white"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      p={3}
      borderRadius={8}
      minHeight={300}
    >
      {logos.map(logo => (
        <Box width={[1 / 3, 1 / 5]} key={logo} textAlign="center">
          <Image maxHeight={30} maxWidth={80} src={logo} alt=""/>
        </Box>
      ))}
    </Box>
  );
};

export default CardCustomers;