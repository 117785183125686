import React from "react";
import { graphql } from "gatsby";
import theme from "../styles/theme";
import { Box, Color, Container, Lead, T1, T3, Image } from "../styles/Styles";
import Img from "gatsby-image";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import TeamSlider from "../components/TeamSlider";
import CardCustomers from "../components/CardCustomers";
import CardMedia from "../components/CardMedia";
import CardProductHypercast from "../components/CardProductHypercast";
import CardProductAPI from "../components/CardProductAPI";
import CardProductWAI from "../components/CardProductWAI";
import CardProductOrg from "../components/CardProductOrg";
import LazyLoad from "react-lazyload";
import hero from "../img/company-hero.png";
import ic70 from "../img/icon-hex-70.svg";
import ic85 from "../img/icon-hex-85.svg";
import icTop5 from "../img/icon-hex-top5.svg";
import ic90 from "../img/icon-hex-90.svg";
import icN1 from "../img/icon-hex-n1.svg";
import sputnik from "../img/bg-sputniks.svg";
import logoEnergyVentures from "../img/energy-venture-logo.png";
import money from "../img/icon-round-money.svg";
import loc from "../img/icon-round-loc.svg";
import hands from "../img/icon-round-hands.svg";
import people from "../img/icon-round-people.svg";
import chip from "../img/icon-round-chip.svg";
import schwartz from "../img/schwartz-hex.png";
import boyle from "../img/boyle-hex.png";
import shavit from "../img/shavit-hex.png";
import cheever from "../img/cheever-hex.png";
import elkabetz from "../img/elkabetz-hex-light.png";
import goffer from "../img/goffer-hex-light.png";
import zlotnik from "../img/zlotnik-hex-light.png";
import featureData from "../img/feature-data.png";
import featureModels from "../img/feature-models.png";


const figures = [
  {
    icon: ic70,
    text: `70% のビジネスは天候に影響を受けている`
  },
  {
    icon: ic85,
    text: `地球上の85%は、頼れるリアルタイムの<nobr>気象予報</nobr>データがない`
  },
  {
    icon: icTop5,
    text: `天候の影響と変化は、CEOが悩むトップ５に挙げられている`
  },
  {
    icon: ic90,
    text: `90%のビジネスは気象データが自社にどう影響するのか理解していない
`
  },
  {
    icon: icN1,
    text: `一番の不満は、行動を起こすための、気象データの正確性が欠けること
`
  }
];

const stats = [
  {
    icon: money,
    text: `初期資本2016`
  },
  {
    icon: loc,
    text: `4拠点`
  },
  {
    icon: hands,
    text: `>1000 顧客`
  },
  {
    icon: people,
    text: `105 従業員`
  },
  {
    icon: chip,
    text: `700+ テラバイトデータを作成`
  }
];

const investpartners = [
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-canaan.jpg",
    link: "https://www.canaan.com/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-сlearvision.jpg",
    link: "https://www.clearvisionventures.com/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-fontinalis.png",
    link: "https://www.fontinalis.com/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-square.png",
    link: "https://www.squarepegcap.com/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/07/logo-softbank-color.png",
    link: "https://www.softbank.jp/en/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/07/logo-ford-color.png",
    link: "https://www.ford.com/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-project11.png",
    link: "https://www.project11.com/"
  },
  {
    logo: "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-tata.png",
    link: "https://www.tatatrusts.org/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-syndicate.png",
    link: "https://thegraduatesyndicate.com/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-jetblue-tv.jpg",
    link: "https://www.jetblueventures.com/"
  },
  {
    logo: "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-wrn.png",
    link: "https://www.weather.gov/wrn/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-legatum.png",
    link: "https://legatum.mit.edu/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-harvard.png",
    link: "https://i-lab.harvard.edu/"
  },
  {
    logo: "https://cms2.climacell.co/wp-content/uploads/2019/03/logo-mc.png",
    link: "https://boston.masschallenge.org/"
  },
  {
    logo:
      "https://cms2.climacell.co/wp-content/uploads/2019/07/icon-logo-national-grid.jpg",
    link: "https://ngpartners.com/"
  },
  {
    logo: logoEnergyVentures,
    link: "http://evergyventures.com/"
  }
];

const board = [
  {
    name: "Philippe Schwartz",
    position: "Partner, Square Peg Capital",
    image: schwartz
  },
  {
    name: "Rich Boyle",
    position: "General Partner, Canaan",
    image: boyle
  },
  {
    name: "Eyal Shavit",
    position: "President, Axcessnet",
    image: shavit
  },
  {
    name: "Chris Cheever",
    position: "Founder & Partner, Fontinalis Partners",
    image: cheever
  },
  {
    name: "Shimon Elkabetz",
    position: "CEO, Co-Founder, tomorrow.io",
    image: elkabetz
  },
  {
    name: "Rei Goffer",
    position: "CSO, Co-Founder, tomorrow.io",
    image: goffer
  },
  {
    name: "Itai Zlotnik",
    position: "CCO, Co-Founder, tomorrow.io",
    image: zlotnik
  }
];

export default ({ data, location }) => {
  return (
    <Layout>
      <SEO
        title="tomorrow.ioへようこそ"
        pathname={location.pathname}
        image={hero}
      />

      <Container py={5}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box width={[1, "55%"]} pr={[0, 4]}>
            <T1>
            tomorrow.ioへようこそ
            </T1>
            <Lead mb={4}>
              弊社は、世界中の実用的な気象インサイトを提供します。私たちのミッションは、気象に関連した挑戦を、最適な情報と気づきによって、上手く対処できるよう<Color color={theme.color.primary}>人々と組織をサポート</Color>することです。
            </Lead>
          </Box>
          <Box width={[1, "45%"]}>
            <Img
              fluid={data.hero.childImageSharp.fluid}
              alt="tomorrow.ioへようこそ"
            />
          </Box>
        </Box>
      </Container>

      <Container py={5}>
        <T3 mb={5}>弊社が必要とされる理由は？</T3>
        <Box display="flex" flexWrap="wrap">
          {figures.map((item, i) => (
            <Box width={[1, 1 / 2]} display="flex" alignItems="center" mb={4} key={i}>
              <Image width={100} src={item.icon} mr={[3, 4]} alt=""/>
              <Lead pr={[0, 5]} dangerouslySetInnerHTML={{ __html: item.text }}/>
            </Box>
          ))}
        </Box>
      </Container>

      <Box background={`url(${sputnik}) no-repeat center / cover`} minHeight={400} display="flex" alignItems="center">
        <Container>
          <T3 mb={3}>なぜ他の気象予報は不十分なのでしょう？</T3>
          <Lead width={[1, 1 / 2]}>
            すべての気象データとモデルアウトプットは、政府により提供<nobr>された</nobr>もので、 民間企業によって再構成されたものだから。
          </Lead>
        </Container>
      </Box>

      <Container py={5}>
        <T3 mb={5}>何が私たちの独自性でしょう？</T3>
        <div className="row align-items-center mx-0">
          <div className="col-md-4 text-center">
            <Image
              maxWidth={240}
              maxHeight={200}
              mb={4}
              src={featureData}
              alt=""
            />
          </div>
          <div className="col-lg-5 col-md-8">
            <h4 className="h-4 fw-800 mb-4">
              データ：ウェザー・オブ・シングス(WoT)
            </h4>
            <p>
              IoT、ドローン、航空機、セルラー信号、衛星通信信号、ライブカメラ等といった、独自のリソースから収集した膨大なデータを従来のデータと統合。
            </p>
          </div>
        </div>
        <div className="row align-items-center mx-0">
          <div className="col-md-4 text-center">
            <Image
              maxWidth={200}
              maxHeight={200}
              mb={4}
              src={featureModels}
              alt=""
            />
          </div>
          <div className="col-lg-5 col-md-8">
            <h4 className="h-4 fw-800 mb-4">MicroWeather モデル</h4>
            <p>
              自社で研究開発した独自のモデルは、過去（AIモデルトレーニング）、リアルタイム、ナウキャスティング（分単位）、弊社のCBAMモデル（連日の予報）において最高の精度に達するために最適化。時間毎の代わりに分単位で、キロメーターの代わりにメーター単位で、グローバルに展開。
            </p>
          </div>
        </div>
      </Container>

      <Container py={5}>
        <T3 mb={5}>ただの気象予報ではない、それはインパクト。</T3>
        <LazyLoad height={600} once>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" mb={4}>
            <Box width={[1, "49%"]}>
              <CardProductHypercast/>
            </Box>
            <Box width={[1, "49%"]}>
              <CardProductAPI/>
            </Box>
          </Box>
        </LazyLoad>
        <LazyLoad height={300} once>
          <CardProductWAI/>
        </LazyLoad>
      </Container>

      <Box bg={theme.color.light} py={5}>
        <Container>
          <T3 mb={4}>新興国における弊社の取り組み</T3>
          <CardProductOrg/>
        </Container>
      </Box>

      <Box maxWidth={1000} mx="auto" py={5} textAlign="center">
        <T3 mb={5}>軌跡</T3>
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {stats.map((item, i) => (
            <Box width={[1 / 2, 1 / 3]} mb={4} key={i}>
              <Image width={100} src={item.icon} alt=""/><br/><br/>
              <Lead dangerouslySetInnerHTML={{ __html: item.text }}/>
            </Box>
          ))}
        </Box>
      </Box>

      <Box maxWidth={900} pb={5} mx="auto">
        <Container>
          <T3 mb={5} textAlign="center">リーダーシップチーム</T3>
          <LazyLoad height={600} once>
            <TeamSlider/>
          </LazyLoad>
        </Container>
      </Box>

      <Box bg={theme.color.light} py={5}>
        <Container display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box width={[1, 1, "49%"]} mb={4}>
            <Lead mb={4} fontWeight={800} textAlign="center">お客様</Lead>
            <CardCustomers/>
          </Box>
          <Box width={[1, 1, "49%"]} mb={4}>
            <Lead mb={4} fontWeight={800} textAlign="center">プレス</Lead>
            <CardMedia/>
          </Box>
        </Container>
      </Box>

      <Container py={5} textAlign="center">
        <T3 mb={5}>取締役</T3>
        <LazyLoad height={300} once>
          <Box display="flex" justifyContent="center" flexWrap="wrap">
            {board.map((item, i) => (
              <Box width={[1 / 2, 1 / 4]} mb={4} key={i}>
                <Image width={120} mb={3} src={item.image} alt=""/>
                <Lead as="div" dangerouslySetInnerHTML={{ __html: item.name }}/>
                <Box px={[1, 3]} mb={4} fontSize={[2, 3]}>{item.position}</Box>
              </Box>
            ))}
          </Box>
        </LazyLoad>
      </Container>

      <Container pb={5} textAlign="center">
        <T3 mb={5}>投資家とパートナー</T3>
        <LazyLoad height={500} once>
          <Box display="flex" justifyContent="center" flexWrap="wrap">
            {investpartners.map((item, i) => (
              <Box as="a" width={[1 / 2, 1 / 4]} mb={4} key={i} href={item.link}>
                <Image width={110} mb={3} src={item.logo} alt=""/>
              </Box>
            ))}
          </Box>
        </LazyLoad>
      </Container>

    </Layout>
  );
};

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "company-hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
