import React from "react";
import EmblaCarouselReact from "embla-carousel-react";
import arrow from "../img/arrow-gray.svg";
import elkabetz from "../img/elkabetz-hex.png";
import elkabetzG from "../img/elkabetz-hex-gray.png";
import goffer from "../img/goffer-hex.png";
import gofferG from "../img/goffer-hex-gray.png";
import zlotnik from "../img/zlotnik-hex.png";
import zlotnikG from "../img/zlotnik-hex-gray.png";
import barak from "../img/barak-hex.png";
import barakG from "../img/barak-hex-gray.png";
import hawley from "../img/hawley-hex.png";
import hawleyG from "../img/hawley-hex-gray.png";
import gonczarowski from "../img/gonczarowski-hex.png";
import gonczarowskiG from "../img/gonczarowski-hex-gray.png";
import peffers from "../img/peffers-hex.png";
import peffersG from "../img/peffers-hex-gray.png";
import rothenberg from "../img/rothenberg-hex.png";
import rothenbergG from "../img/rothenberg-hex-gray.png";
import arditi from "../img/arditi-hex.png";
import arditiG from "../img/arditi-hex-gray.png";
//import allerhand from "../img/allerhand-hex.png";
//import allerhandG from "../img/allerhand-hex-gray.png";
import itach from "../img/itach-hex-blue.png";
import itachG from "../img/itach-hex-gray.png";
import slagen from "../img/slagen-hex-blue.png";
import slagenG from "../img/slagen-hex-gray.png";


const team = [
  {
    id: 0,
    name: "Shimon Elkabetz",
    position: "CEO、Co-Founder",
    about: `シモンはイスラエル空軍に11年間、様々な管理職ならびに指令ポジションとして携わりました。幾度ともなく遭遇した、生死を脅かすような気象関連の経験を軍兵役中にしたことで、気象に関する特別な関心が生まれ、今現在私たち全員に影響しています。シモンはサッカーを大変好み、勝ち負けの統計を共有してくれます。シモンは、ベン・グリオン大学で経済学の学士を有し、ハーバード大学のMBAを修了しました。`,
    imgGray: elkabetzG,
    imgColor: elkabetz
  },
  {
    id: 1,
    name: "Rei Goffer",
    position: "CSO, Co-Founder",
    about: `レイはイスラエル空軍に10年間、研究開発ならびに複数部門が関わるオペレーションに携わりました。世の中を一変させ、奇想天外なアイデアを現実とするようなテクノロジーを開発することに情熱を持ちます。レイは食通で、料理をする事も食べる事も好み、また音楽と暖かい場所が好きです。ベン・グリオン大学で経済学の学士を有し、マサチューセッツ工科大学のMBA、さらにハーバード大学ジョン・F・ケネディ政治大学院で修士を修了しました。`,
    imgGray: gofferG,
    imgColor: goffer
  },
  {
    id: 2,
    name: "Itai Zlotnik",
    position: "CCO, Co-Founder",
    about: `イタイはイスラエル軍のエリート部隊で士官として6年間携わりました。その後、Apple社でソフトウェアエンジニアになり、携帯機器のメモリーハードウェアに関する技術を監督し、導入しました。イタイは、大食い大会で優勝できたかもしれない一面を持つと共に、子供の世話をしなくていい時には自由なダイビングを楽しみます。イタイは、テルアビブ大学で電気工学の学位を有し、マサチューセッツ工科大学でMBAを修了しました。`,
    imgGray: zlotnikG,
    imgColor: zlotnik
  },
  {
    id: 3,
    name: "Osnat Barak",
    position: "Chief of People, Operations & Culture",
    about: `オスナットは、複数のグローバルスタートアップ企業で、18年間にわたる人事ならびに文化リーダーシップの経験を有します。前職は、イスラエルSearsにおける人事部長で、6年間で社員数が5倍以上にもなった企業の成長をサポートしてきました。さらにここ数年間は、組織コンサルタントとして二つのNGOのボランティアをしています。オスナットは、テルアビブ大学の組織的行動科目の修士課程と共に、グループ・ファシリテーションとコーチングの学位を有します。プライベート時間では、幼い双子と有意義な時間を過ごします。`,
    imgGray: barakG,
    imgColor: barak
  },
  {
    id: 4,
    name: "Alan Hawley",
    position: "Chief Revenue Officer",
    about: `アランは、15年以上にわたって、SaaSならびにインフラ技術の急成長企業において、最先端の優秀なセールスチームを率いた経験を有します。tomorrow.ioに入社前、アランはCompuware Corporation [CPWR]、Everbridge [EVBG]、Smartbear Software、そしてVoltDBなどでエグゼクティブ・レベルのポジションを経験しました。前職のVoltDB社では、グローバルセールス部長を務め、北米・欧州・アジアにおける主要業界マーケットにおいて、顧客重視ソリューションに焦点を当てることにより、年間収益を比類なく増加させました。<br/>欧州で注目すべき大学スポーツのキャリアを終えて、アランはその成功したスポーツチームでの全ての経験を活かして、現在のマネージメントスタイルへと発展させました。アランは、チームの成功の秘訣を、信頼、情熱、結果重視といった顧客重視のチームをまとめる事で、キャリア形成をしてきました。`,
    imgGray: hawleyG,
    imgColor: hawley
  },
  {
    id: 5,
    name: "Effie Arditi",
    position: "Chief Product Officer",
    about: `エフィーは、20年以上にわたる大規模なB2BやB2Cプロダクトの構築と、大きなグループを率いる経験を有します。tomorrow.ioの前に、エフィーは、ボストン・コンサルティンググループのデジタルベンチャーにおいてエンジニアリングダイレクターを務め、イノベーション・戦略・技術においてフォーチューン2000企業と仕事をしていました。エフィーはまた、ホームオートメーションとセキュリティで欧州の最大手ブランドであるEssenceにおいて、研究開発部長でもあり、ソフトウェア部門をまとめ、グローバル消費者マーケットにおける最初の一歩を構築しました。大企業でのバックグラウンドと共に、エフィーは、B2Bを対象としたSaaSとB2C向き、フィンテック業界で3つの会社を起業した、注目すべき起業家としての経験も有します。エフィーは、成長が早く、協調性を有し、スケーラブルなチームを構築することに情熱を注ぎます。`,
    imgGray: arditiG,
    imgColor: arditi
  },
  {
    id: 6,
    name: "Yuval Gonczarowski",
    position: "Chief Technology Officer",
    about: `ユバルは、新技術を世に出し、プロトタイプ化する経験を数十年にわたって有します。8200というイスラエル軍のエリート・シグナルインテリジェンス部においてキャプテンを務め、革新的なチームやプロジェクトを率い、ラピッド・プロトタイピング機器をインテル社（高速ネットワーキング）、Apple社（保管デバイスとAppleウォッチ）にもたらしました。また、マッキンゼー・アンド・カンパニー社と共に、多くのテクノロジー企業にデジタルと技術革新についてアドバイスをしてきました。ユバルは、イスラエル工科大学の電気工学で学士を有し（magna cum laude：優等生）、ハーバード・ビジネススクールのMBAを修了しています（with distinction：優秀）。`,
    imgGray: gonczarowskiG,
    imgColor: gonczarowski
  },
  {
    id: 7,
    name: "Luke Peffers, PhD",
    position: "SVP, Head of NWP Division",
    about: `ペファー博士は、18年以上にわたる現役アクティブサービスメンバーとして、アメリカ空軍における公務員、アメリカ国防総省での契約主席科学官、世界中のエンブリー・リドル航空大学キャンパスにおける、気象学の非常勤教授を経て、現在tomorrow.ioで数値気象予報部門の専務取締役です。ペファー博士は、アメリカ空軍技術応用センター（U.S. Air Force Technical Applications Center）との共同で、大気学の博士課程を修了しました。博士課程修了後、ペファー博士は、アメリカ空軍技術応用センターにおいて、世界の核実験禁止条約の順守を監視するための、気象モデリングのデザインと運用を担当する、気象学モデルリングならびに分析チームの支部主席を務めました。ペファー博士はその後、STAR社に主席科学官として入社し、アメリカ国防総省との契約や気象モデルの運用、気象に関連する化学、生物学、放射線学、原子核（CBRN）の法定分析や予報システムをアメリカ国防総省内の様々な部門や外国政府に対して、開発、是認を必要とするプロジェクトに関わりました。`,
    imgGray: peffersG,
    imgColor: peffers
  },
  {
    id: 8,
    name: "Daniel Rothenberg, PhD",
    position: "Chief Scientist",
    about: `tomorrow.ioに入社する前、ダニエルはマサチューセッツ工科大学の科学官で、新しいモデリングツールを開発しました。当該分野のリーダーとして、科学学会を企画・開催し、大量の気象データセットを用いた、高性能でオープンソースのツールキットを構築する、地球科学における新たなイニシアティブを見出すのために活躍しました。博士課程前に、コーネル大学で大気科学の学士を修めました。ダニエルは全ての分野で❝ビックデータ❞を利用することに情熱を持ち、より良い意思決定と生活や資産を守るために、特に天気と気候においては重要視します。`,
    imgGray: rothenbergG,
    imgColor: rothenberg
  },
  {
    id: 9,
    name: "Nadav Itach",
    position: "SVP and Managing Director, APAC",
    about: `ナダブはtomorrow.ioのAPACにおけるビジネスを総管轄し、企業戦略、セールス、マーケティング、事業開発、カスタマーサクセスといった部門の責任を持ちます。ナダブは、アジア地域で約10年勤務しており、セールス、事業開発、オペレーションで管理職を務めました。tomorrow.ioの現職前にナダブは、Trax Retail社で事業本部長としてAPACならびに中国地域においてTrex社の事業確立と成長を任されました。ナダブは、イノベーションに情熱を持ち、革新的な最新技術には、企業の成長が必ず存在する事に確信を持っています。アジア圏において、テクノロジー企業の急成長を導き、カスタマーサクセスに焦点をあてた、高い個人スキルも持ったチームを発展させていく実績があります。ナダブは、エルサレムのヘブライ語大学で国際関係ならびに東南アジア学で学士を有します。`,
    imgGray: itachG,
    imgColor: itach
  },
  {
    id: 10,
    name: "Dan Slagen",
    position: "SVP of Marketing",
    about: `ダンは、初期スタートアップから年間経常収益1億ドル以上の企業において、グローバルな事業戦略の拡大を専門とするエグゼクティブを4度経験しました。HubSpotやWayfairといったB2BならびにB2Cの企業での経験を元に、ダンはマーケティング、成長、セールス、カスタマーサクセス、事業開発に渡るチームを築き、さらに自身でビデオ・テックのスタートアップを起業し、エグジットの経験を持ち合わせます。スタートアップのコミュニティに頻繁に参加し、50以上のコンフェランスで登壇もし、ニューヨークタイムズやウォールストリートジャーナル、フォーブス、CNBC、テッククランチ、ブルーンバーグTVなどでも取り上げられるなどしています。ダンは、創造性、意欲、人第一主義です。`,
    imgGray: slagenG,
    imgColor: slagen
  }
];

export default () => {
  const [embla, setEmbla] = React.useState(null);
  const [selected, setSelected] = React.useState(team[0]);
  const [prev, setPrev] = React.useState(false);
  const scrollPrev = React.useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = React.useCallback(() => embla.scrollNext(), [embla]);

  React.useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setPrev(true);
      });
    }
  }, [embla]);

  return (
    <>
      <div className="position-relative">
        <EmblaCarouselReact
          htmlTagName="div"
          emblaRef={setEmbla}
          options={{
            slidesToScroll: 1,
            containScroll: true
          }}
        >
          <div className="cc-team-slider">
            {team.map((item, index) => (
              // eslint-disable-next-line
              <div
                key={item.id}
                className="cc-team-slider-item"
                onClick={() => setSelected(item)}
                role="button"
                tabIndex="0"
              >
                <img
                  alt=""
                  className="cc-team-slider-image"
                  src={selected.id === index ? item.imgColor : item.imgGray}
                />
                <div className="cc-team-slider-name">{item.name}</div>
              </div>
            ))}
          </div>
        </EmblaCarouselReact>

        {prev && (
          <button className="cc-team-slider-button -prev" onClick={scrollPrev}>
            <img src={arrow} alt=""/>
          </button>
        )}

        <button className="cc-team-slider-button -next" onClick={scrollNext}>
          <img src={arrow} alt=""/>
        </button>
      </div>

      <div className="row pt-lg-4 px-lg-5 mx-0">
        <div className="col-lg-5 d-none d-lg-block">
          <img
            height={290}
            src={selected.imgColor}
            alt=""
          />
        </div>
        <div className="col-lg-7">
          <h3 className="ff-normal fw-500 fancy-arrow-right">
            {selected.name}
          </h3>
          <p>{selected.position}</p>
          <div dangerouslySetInnerHTML={{ __html: selected.about }}/>
        </div>
      </div>
    </>
  );
};
