import React from "react";
import { Box, Image } from "../styles/Styles";
import wash from "../img/washington-logo.svg";
import fortune from "../img/fortune-logo.svg";
import wsj from "../img/wall-street-logo.svg";
import etimes from "../img/etimes-logo.svg";
import bi from "../img/business-insider-logo.svg";
import forbes from "../img/forbes-logo.svg";
import time from "../img/time-logo.svg";
import fast from "../img/fast-logo.svg";
import mit from "../img/mit-logo.svg";
import bloom from "../img/bloomberg-logo.svg";
import tc from "../img/tc-logo.svg";
import nweek from "../img/newsweek-logo.svg";


const items = [
  {
    logo: bloom,
    title:
      "“Startup Street: This Ratan Tata-Backed Startup Predicts Floods In India, Days In Advance”",
    link:
      "https://www.bloombergquint.com/technology/startup-street-this-ratan-tata-backed-startup-predicts-floods-in-india-days-in-advance"
  },
  {
    logo: fortune,
    title:
      "“tomorrow.io’s New Weather App Uses 600 Million Devices to Forecast Areas as Small as a City Block”",
    link: "https://fortune.com/2019/08/13/climacell-best-weather-forecast-app/"
  },
  {
    logo: forbes,
    title:
      "“tomorrow.io, combines data analytics, traditional inputs and the analysis of signals beamed between cellular" +
      " towers to predict local weather six hours in advance. It’s a game changer for business”",
    link:
      "https://www.forbes.com/sites/jonmarkman/2019/07/30/this-startup-uses-cell-tower-signals-to-accurately-predict-weather/"
  },
  {
    logo: mit,
    title:
      "“One airline did better than most, however. Instead of relying on the usual weather forecasts, it listened to tomorrow.io—a Boston-based “weather tech” startup that claims it can predict the weather more accurately than anyone else”",
    link:
      "https://www.technologyreview.com/s/613445/a-weather-tech-startup-wants-to-do-forecasts-based-on-cell-phone-signals/"
  },
  {
    logo: wash,
    title:
      "“Meet the tomorrow.io weather app. Alerting you when it’s about to rain, down to the minute, all around the world”",
    link:
      "https://www.washingtonpost.com/weather/2019/08/12/meet-climacell-weather-app-alerting-you-when-its-about-rain-down-minute-all-around-world/"
  },
  {
    logo: bi,
    title:
      "“When is the lightning going to come? When do we need to roll the deicing trucks? When we have much more precise weather, it makes a much more seamless journey for you”",
    link:
      "https://www.businessinsider.com/jetblue-venture-capital-reveals-top-investments-travel-disruptions-weather-delays-2019-10"
  },
  {
    logo: wsj,
    title:
      "“tomorrow.io predicts weather by a few hundred feet. Think about a thunderstorm coming into JFK. tomorrow.io uses cell towers to track the transmission from one cell tower to another to monitor precipitation and predict weather to that precise location“",
    link:
      "https://www.wsj.com/articles/what-jetblue-is-doing-in-silicon-valley-11572184801"
  },
  {
    logo: fast,
    title:
      "“tomorrow.io’s weather-of-things approach to forecasting helps city planners to prepare for bad weather”",
    link:
      "https://www.fastcompany.com/90342648/your-smartphones-wireless-signal-can-be-used-to-forecast-the-weather"
  },
  {
    logo: etimes,
    title:
      "“tomorrow.io, a firm which provides accurate weather forecast in a bid to alert people of upcoming floods by using day-to-day devices as environmental sensors”",
    link:
      "https://economictimes.indiatimes.com/small-biz/startups/features/baked-by-ratan-tata-softbank-this-startup-predicts-when-the-next-flood-will-hit-you-climacell/articleshow/69019435.cms"
  },
  {
    logo: time,
    title:
      "“Inside the Weather Wars That May Threaten the Daily Forecast You Depend On”",
    link:
      "https://time.com/5615625/private-companies-weather-forecasting-threat/"
  },
  {
    logo: tc,
    title:
      "“That’s where tomorrow.io’s main innovation comes in. Instead of relying on government sensors, it’s using" +
      " the Internet of Things to gather more weather data from far more places than would otherwise be possible”",
    link:
      "https://techcrunch.com/2019/03/18/climacell-bets-on-iot-for-better-weather-forecasts/"
  },
  {
    logo: nweek,
    title:
      "“Farmers can now accurately know if they need to irrigate their crops, airports can be armed with the information they need to decide whether they will need to de-ice planes, and roofers can receive a warning on whether they will lose a day of work”",
    link:
      "https://www.newsweek.com/israel-politics-paralyzed-economy-tech-fine-1469659"
  }
];

const CardMedia = () => {
  return (
    <Box
      bg="white"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      p={3}
      borderRadius={8}
      minHeight={300}
    >
      {items.map(item => (
        <Box width={[1 / 3, 1 / 4]} key={item.logo} textAlign="center">
          <a href={item.link}>
            <Image maxWidth={[80,105]} maxHeight={40} src={item.logo} alt=""/>
          </a>
        </Box>
      ))}
    </Box>
  );
};

export default CardMedia;